import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from "../services/settings.service";
import { DigitalSignageSlideModel } from "../models/digital-signage-slide.model";
import { TourTimeGroupsService } from "../webservices/tour-time-groups.service";
import { TourTimeGroupModel } from "../models/tour-time-group.model";
import { EventTimeService } from "../webservices/event-time.service";
import { environment } from "../../environments/environment";
import { EventTimeModel } from "../models/event-time.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent implements OnInit, OnDestroy {

  time;
  date;
  slide: DigitalSignageSlideModel;
  tourTimeGroups: TourTimeGroupModel[];
  eventTimes: EventTimeModel[];
  nextEventTime?: EventTimeModel;
  nextTourTime?: TourTimeGroupModel;
  tourTitle:string;
  loadDataIntervalId;

  constructor(private datePipe: DatePipe,
              private settingsService: SettingsService,
              private tourTimeGroupsService: TourTimeGroupsService,
              private  eventTimeService: EventTimeService) { }

  ngOnInit(): void {
    setInterval(() => {
      this.time = this.datePipe.transform(new Date(), 'h:mm a');
      this.date = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
    }, 1000);

    this.slide = this.settingsService.getCurrentSlide();

    this.loadDataIntervalId = setInterval(() => {
      this.loadData();
    }, environment.refreshTime);
    this.loadData();

  }

  ngOnDestroy() {
    clearInterval(this.loadDataIntervalId);
  }

  findNextTourTime() {
    const next = this.tourTimeGroups.find((tourTimeGroup) => {
      return tourTimeGroup.status === 'pending';
    });
    if (next) {
      this.nextTourTime = next;
    } else {
      this.nextTourTime = null;
    }
  }

  loadData() {
    if (this.slide.type === 'today_events_all') {
      this.tourTimeGroupsService.get({status__in: 'pending,boarding_in_progress,cancelled', app_id: 38, show_on_digital_display: 1}).subscribe(data => {
        this.tourTimeGroups = [...data['objects']];
        this.findNextTourTime();
        this.tourTitle = 'TRAM TOURS';
        console.log(this.tourTimeGroups);
      });
    } else {
      this.tourTimeGroupsService.get({tourId: this.slide.tour.id, status__in: 'pending,boarding_in_progress,cancelled', show_on_digital_display: 1}).subscribe(data => {
        this.tourTimeGroups = [...data['objects']];
        this.findNextTourTime();
        this.tourTitle = this.slide.tour.name;
        console.log(this.tourTimeGroups);
      });
    }

    let today = new Date();

    this.eventTimeService.get({
      in_progress: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
      limit: 1000,
      app: environment.app,
      nocache: 1
    }).subscribe(data => {
      this.eventTimes = [...data['objects']].filter((eventTime: EventTimeModel) => {
        let eventDate = new Date(eventTime.start_time * 1000);
        let endDate = new Date(eventTime.end_time * 1000);
        return (today.toDateString() === eventDate.toDateString()) && (new Date(eventTime.end_time * 1000) > today);
      }).slice(0, 6);
      let next = this.eventTimes.find((eventTime) => {
        let eventDate = new Date(eventTime.start_time * 1000);
        return eventDate > today;
      });
      if (next) {
        this.nextEventTime = next;
      }
    });
  }

  statusLabel(tourTimeGroup: TourTimeGroupModel) {
    if (tourTimeGroup.status === 'boarding_in_progress') {
      return 'Boarding in Progress';
    }
    if (tourTimeGroup.status === 'boarding_next') {
      return 'Boarding Next';
    }
    if (tourTimeGroup.status === 'pending') {
      if (tourTimeGroup === this.nextTourTime) {
        return 'Next';
      }
      return 'Upcoming';
    }
    if (tourTimeGroup.status === 'cancelled') {
      return 'Cancelled';
    }
  }

  formattedTime(timestamp) {
    return this.datePipe.transform(new Date(timestamp * 1000), 'h:mm a');
  }

}
