import { Component } from '@angular/core';
import { SettingsService } from "./services/settings.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'guru-sch-digitaldisplay';

  constructor(private settingsService: SettingsService){
    this.init();
  }

  init() {
  }
}
