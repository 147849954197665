import { Injectable } from '@angular/core';
import { DigitalSignageService } from "../webservices/digital-signage.service";
import { DigitalSignageConfigModel } from "../models/digital-signage-config.model";
import { count } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  config?: DigitalSignageConfigModel;
  currentIndex = -1;
  displayName = null;
  intervalId;
  loadDataIntervalId;

  constructor(private digitalSignageService: DigitalSignageService, private  router: Router) {
  }

  initWithDisplayName(displayName) {
    this.displayName = displayName;
    console.log('init display: ' + displayName);


    setInterval(() => {
      this.loadConfig();
    }, environment.configRefreshTime);
    this.loadConfig();
  }

  loadConfig() {

      this.digitalSignageService.getDetail(this.displayName).subscribe((result: DigitalSignageConfigModel) => {
        console.log(result.slides);

        if (!this.config || this.config.display_time !== result.display_time) {
          this.config = result;
          console.log('restart interval');
          // restart interval with new display time
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          this.intervalId = setInterval(() => {
            this.showNextSlide();
          }, result.display_time * 1000);
          this.showNextSlide();
        } else {
          this.config = result;
        }

      });

  }

  showNextSlide() {
    if (!this.config || this.config.slides.length === 0) {
      return;
    }
    this.currentIndex++;
    if (this.config.slides.length <= this.currentIndex) {
      this.currentIndex = 0;
    }
    let slide = this.config.slides[this.currentIndex];

   this.router.navigate(['/'+slide.type]);
  }

  getCurrentSlide() {
    if (this.currentIndex >= 0 && this.currentIndex < this.config?.slides?.length) {
      return this.config.slides[this.currentIndex];
    }
    return null;
  }
}
