import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsPageComponent } from './events-page/events-page.component';
import { DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { BoardingGroupsPageComponent } from './boarding-groups-page/boarding-groups-page.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';

@NgModule({
  declarations: [
    AppComponent,
    BoardingGroupsPageComponent,
    EventsPageComponent,
    BoardingGroupsPageComponent,
    LoadingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    RouterModule.forRoot([
      {path: '', component: LoadingPageComponent},
      {path: 'boarding_groups', component: BoardingGroupsPageComponent},
      {path: 'boarding_groups_all', component: BoardingGroupsPageComponent},
      {path: 'today_events', component: EventsPageComponent},
      {path: 'today_events_all', component: EventsPageComponent},
      {path: ':display', component: LoadingPageComponent}
    ], {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'}),
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
