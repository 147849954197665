import { Injectable, Injector, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

export class BaseService {

  url: string;
  http: any;
  router: any;

  constructor(private injectorObj: Injector, url: string) {
    this.http = this.injectorObj.get(HttpClient);
    this.router = this.injectorObj.get(Router);
    this.url = url;
  }

  /**
   * Lists
   */
  get(params?) {
    return this.http.get(environment.apiUrl + this.url + '?', {params});
  };

  getDetail(id) {
    return this.http.get(environment.apiUrl + this.url + id + '/');
  };
}
