import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from "@angular/common";
import { TourTimeGroupsService } from "../webservices/tour-time-groups.service";
import { DigitalSignageSlideModel } from "../models/digital-signage-slide.model";
import { SettingsService } from "../services/settings.service";
import { TourTimeGroupModel } from "../models/tour-time-group.model";
import { ActivatedRoute } from '@angular/router';
import { Environment } from '@angular/compiler-cli/src/ngtsc/typecheck/src/environment';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-boarding-groups-page',
  templateUrl: './boarding-groups-page.component.html',
  styleUrls: ['./boarding-groups-page.component.scss'],
})
export class BoardingGroupsPageComponent implements OnInit, OnDestroy {

  constructor(private datePipe: DatePipe, private tourTimeGroupsService: TourTimeGroupsService,
              private settingsService: SettingsService, private activatedRoute: ActivatedRoute) {
    console.log('Reload2');
  }

  time;
  tourTimeGroups: TourTimeGroupModel[];
  slide: DigitalSignageSlideModel
  nextTourTime?: TourTimeGroupModel;
  tourTitle: string;
  loadDataIntervalId;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log('Reload3');
    });
    console.log('Reload1');
    this.slide = this.settingsService.getCurrentSlide();
    if (this.slide == null) return;

    setInterval(() => {
      this.time = this.datePipe.transform(new Date(), 'h:mm a');
    }, 1000);

    this.loadDataIntervalId = setInterval(() => {
      this.loadData();
    }, environment.refreshTime);
    this.loadData();
  }

  ngOnDestroy() {
    clearInterval(this.loadDataIntervalId);
  }

  loadData() {
    if (this.slide.type === 'boarding_groups_all') {
      this.tourTimeGroupsService.get({status__in: 'pending,boarding_in_progress,cancelled', app_id: 38, show_on_digital_display: 1}).subscribe(data => {
        this.tourTimeGroups = [...data['objects']];
        this.findNextTourTime();
        this.tourTitle = 'TRAM TOURS';
        console.log(this.tourTimeGroups);
      });
    } else {
      this.tourTimeGroupsService.get({tourId: this.slide.tour.id, status__in: 'pending,boarding_in_progress,cancelled', show_on_digital_display: 1}).subscribe(data => {
        this.tourTimeGroups = [...data['objects']];
        this.findNextTourTime();
        this.tourTitle = this.slide.tour.name;
        console.log(this.tourTimeGroups);
      });
    }
  }

  findNextTourTime() {
    let next = this.tourTimeGroups.find((tourTimeGroup) => {
      return tourTimeGroup.status === 'pending';
    });
    if (next) {
      this.nextTourTime = next;
    } else {
      this.nextTourTime = null;
    }
  }

  statusLabel(tourTimeGroup: TourTimeGroupModel) {
    if (tourTimeGroup.status === 'boarding_in_progress') {
      return 'Boarding in Progress';
    }
    if (tourTimeGroup.status === 'boarding_next') {
      return 'Boarding Next';
    }
    if (tourTimeGroup.status === 'pending') {
      if (tourTimeGroup === this.nextTourTime) {
        return 'Next';
      }
      return 'Upcoming';
    }
    if (tourTimeGroup.status === 'cancelled') {
      return 'Cancelled';
    }
  }
}
