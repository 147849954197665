import { Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class DigitalSignageService extends BaseService{

  constructor(private injector: Injector) {
    super(injector, "virtual_queue_digital_signage/"); // /:code/
  }
}
