import { Injectable, Injector } from '@angular/core';
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class EventTimeService extends BaseService{
  constructor(private injector: Injector) {
    super(injector, "eventtime/"); // /?in_progress=2020-6-01&limit=1000&app=38
  }
}



