<div class="d-flex flex-row" style="height: 100vh">
  <div class="flex-grow-1 d-flex flex-column" style="flex-basis: 0">
    <div class="flex-grow-1" style="flex-basis: 0">
    </div>
    <div class="flex-grow-2 text-center" style="flex-basis: 0">
      <img src="../../assets/images/logo.png">
      <h1 class="mt-3">Loading...</h1>
    </div>
    <div class="flex-grow-1" style="flex-basis: 0">
    </div>

  </div>
</div>
