import { Injectable, Injector } from '@angular/core';
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TourTimeGroupsService extends BaseService {

  constructor(private injector: Injector) {
    super(injector, "tour_time_groups/"); //?tour_id=144
  }
}
