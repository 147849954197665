
<div class="d-flex flex-row" style="height: 100vh">
  <div class="flex-grow-1 d-flex flex-column" style="flex-basis: 0">

    <div class="container-fluid flex-grow-2">
      <div class="row">
        <div class="col">
          <img src="../../assets/images/logo.png" class="logo" />
        </div>
        <div class="col">
          <h4>{{date}}</h4>
          <h5>{{time}}</h5>
        </div>
      </div>
    </div>

    <div class="container-fluid flex-grow-1">
      <div class="row mt-3">
        <div class="col-1">
          <hr class="red" />
        </div>
        <div class="col">
          <h1>Todays events</h1>
        </div>
      </div>
      <div *ngFor="let eventTime of eventTimes" class="row">
        <div class="col-1"></div>
        <div class="col-3 event-time">{{formattedTime(eventTime.start_time)}}</div>
        <div class="col event-name" [style.font-weight]="eventTime==nextEventTime?'bold':'regular'">{{eventTime.event.name}}</div>
        <div class="col-2"><span class="next" *ngIf="eventTime==nextEventTime">NEXT</span></div>
      </div>
    </div>


    <div class="container-fluid flex-grow-2">
      <div class="row mt-3">
        <div class="col-1">
          <hr class="red" />
        </div>
        <div class="col">
          <h1>{{tourTitle}} Boarding Next</h1>
        </div>
      </div>
      <div *ngIf="tourTimeGroups" class="row mt-3 mb-5">
        <div class="col-1">
        </div>
        <div *ngIf="tourTimeGroups[0]" class="col team-container text-center">
          <h3>{{tourTimeGroups[0].name}}</h3>
          <h2>{{statusLabel(tourTimeGroups[0])}}</h2>
        </div>
        <div class="col-1">
          <hr />
        </div>
        <div *ngIf="tourTimeGroups[1]" class="col team-container text-center">
          <h3>{{tourTimeGroups[1].name}}</h3>
          <h2>{{statusLabel(tourTimeGroups[1])}}</h2>
        </div>
        <div class="col-1">
          <hr />
        </div>
        <div *ngIf="tourTimeGroups[2]" class="col team-container text-center">
          <h3>{{tourTimeGroups[2].name}}</h3>
          <h2>{{statusLabel(tourTimeGroups[2])}}</h2>
        </div>
      </div>
    </div>


  </div>
  <div class="flex-grow-1 right-column" style="flex-basis: 0"
       [style.background-image]="'url(' + slide?.image?.original + ')'">

    <div class="container-fluid">
      <div class="row info">
        <div class="col-1"></div>
        <div *ngIf="slide?.content" class="col">
          <h6>IMPORTANT INFO</h6>
          <span class="next">CAUTION</span>
          <p class="info-content mt-3" [innerHTML]="slide?.content">
          </p>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</div>
