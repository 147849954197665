<div class="container-fluid">
  <div class="row">
    <div class="col-2 offset-10 time-container text-center p-3">
      {{ time }}
    </div>
  </div>
  <div class="row">
    <h1 class="col text-center mt-5">
      {{tourTitle}} Boarding Next
    </h1>
  </div>
  <div *ngIf="tourTimeGroups" class="row">
    <div *ngIf="tourTimeGroups[0]" class="col team-container text-center">
      <h2>{{statusLabel(tourTimeGroups[0])}}</h2>
      <h3>{{tourTimeGroups[0].name}}</h3>
    </div>
    <div *ngIf="tourTimeGroups[1]" class="col team-container text-center">
      <h2>{{statusLabel(tourTimeGroups[1])}}</h2>
      <h3>{{tourTimeGroups[1].name}}</h3>
    </div>
    <div *ngIf="tourTimeGroups[2]" class="col team-container text-center">
      <h2>{{statusLabel(tourTimeGroups[2])}}</h2>
      <h3>{{tourTimeGroups[2].name}}</h3>
    </div>
  </div>
</div>
